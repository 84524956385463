import TeamCard from "../base/teamCard"
import useSmoothScrollTo from "../../lib/smoothScroll";
import Picker from "../base/picker";
import { useState } from "react"

const cities = [
    "Томск",
    "Новосибирск",
    "Москва",
    "Санкт-Петербург",
    "Красноярск",
    "Риддер",
]

const team = [
    {
        name: "Виолетта",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/0.jpg",
    },
    {
        name: "Кристина",
        job: "Визажист",
        city: "Томск",
        img: "/images/photo/team/1.jpg",
    },
    {
        name: "Виктория",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/2.jpg",
    },
    {
        name: "Мария",
        job: "Cтилист",
        city: "Санкт-Петербург",
        img: "/images/photo/team/3.jpg",
    },
    {
        name: "Ирина",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/4.jpg",
    },
    {
        name: "Александр",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/5.jpg",
    },
    {
        name: "Александра",
        job: "Cтилист",
        city: "Томск",
        img: "/images/photo/team/6.jpg",
    },
    {
        name: "Наталья",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/8.jpg",
    },
    {
        name: "Юлия",
        job: "Визажист",
        city: "Томск",
        img: "/images/photo/team/9.jpg",
    },
    {
        name: "Валерия",
        job: "Визажист",
        city: "Томск",
        img: "/images/photo/team/10.jpg",
    },
    {
        name: "Татьяна",
        job: "Cтилист",
        city: "Томск",
        img: "/images/photo/team/11.jpg",
    },
    {
        name: "Марина",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/12.jpg",
    },
    {
        name: "Евгения",
        job: "Фотограф",
        city: "Томск",
        img: "/images/photo/team/13.jpg",
    },
    {
        name: "Татьяна",
        job: "Визажист",
        city: "Томск",
        img: "/images/photo/team/14.jpg",
    },
    {
        name: "Ирина",
        job: "Фотограф",
        city: "Санкт-Петербург",
        img: "/images/photo/team/15.jpg",
    },
    {
        name: "Мария",
        job: "Визажист",
        city: "Новосибирск",
        img: "/images/photo/team/16.jpg",
    },
    {
        name: "Елена",
        job: "Стилист",
        city: "Новосибирск",
        img: "/images/photo/team/17.jpg",
    },
    {
        name: "Виктория",
        job: "Визажист",
        city: "Москва",
        img: "/images/photo/team/18.jpg",
    },
    {
        name: "Анастасия",
        job: "Визажист",
        city: "Москва",
        img: "/images/photo/team/20.jpg",
    },
    {
        name: "Евгения",
        job: "Фотограф",
        city: "Новосибирск",
        img: "/images/photo/team/21.jpg",
    },
    {
        name: "Мария",
        job: "Визажист и Фотограф",
        city: "Риддер",
        img: "/images/photo/team/22.png",
    },
    {
        name: "Вероника",
        job: "Визажист",
        city: "Москва",
        img: "/images/photo/team/23.jpg",
    },
    {
        name: "Анна",
        job: "Фотограф",
        city: "Москва",
        img: "/images/photo/team/24.jpg",
    },
    {
        name: "Яна",
        job: "Визажист",
        city: "Новосибирск",
        img: "/images/photo/team/25.jpg",
    },
    {
        name: "Евгения",
        job: "Фотограф",
        city: "Красноярск",
        img: "/images/photo/team/27.jpg",
    },
    {
        name: "Натали",
        job: "Визажист",
        city: "Красноярск",
        img: "/images/photo/team/26.jpg",
    },
]

const TeamBlock = () => {
    const teamBind = useSmoothScrollTo('#team');

    const [currentCity, setCurrentCity] = useState(cities[0]);

    return (
        <section {...teamBind} className="text-gray-600 body-font w-full">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full">
                    <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-violet-500">Команда</h1>
                </div>
                <Picker values={cities} currentValue={currentCity} setCurrentValue={setCurrentCity} />
                <div className="flex flex-wrap mt-12">
                    {
                        team.filter(x => x.city === currentCity).map((item, index) => {
                            return <TeamCard item={item} key={index} />
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default TeamBlock