import { useRef } from "react";
import Header3 from "../base/header3";

const Footer = () => {
    const year = useRef(new Date().getFullYear());

    return (
        <footer className="text-white body-font">
            <div class="flex flex-wrap md:text-left text-center order-first container">

            </div>
            <div className="bg-violet-900">
                <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
                    <a href="/">
                        <Header3 text={`© ${year.current} - Nelli Pronevich`} className="cursor-pointer" />
                    </a>
                    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start text-sm lg:text-base">
                        <a href="https://t.me/ViktorTolstov" className="hover:underline cursor-pointer" target="_blank" rel="noreferrer"> MADE by VIKTOR</a>
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer