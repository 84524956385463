import Footer from "./footer";
import Header from "./header";

const Layout = ({ children, isMain = true }) => {
    return (
        <div className="relative">
            <Header isMain={isMain} />
            {children}
            <Footer />
        </div>
    );
}

export default Layout