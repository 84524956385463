import LayoutSlide from '../components/layout/layoutSlide';
import MainBlock from '../components/blocks/mainBlock';
import ProjectsBlock from '../components/blocks/projectsBlock';
import InfoBlock from '../components/blocks/infoBlock';
import ProductionBlock from '../components/blocks/productionBlock';
import ContactBlock from '../components/blocks/contactBlock';
import FeedbackBlock from '../components/blocks/feedbackBlock';
import TeamBlock from '../components/blocks/teamBlock';
import Layout from '../components/layout/layout';

export const MainPage = () => {
    return (
        <Layout isMain={true}>
            <LayoutSlide>
                <MainBlock />
            </LayoutSlide>
            <LayoutSlide>
                <InfoBlock />
            </LayoutSlide>
            <LayoutSlide>
                <ProjectsBlock />
            </LayoutSlide>
            <LayoutSlide>
                <ProductionBlock />
            </LayoutSlide>
            <LayoutSlide>
                <FeedbackBlock />
            </LayoutSlide>
            <LayoutSlide>
                <TeamBlock />
            </LayoutSlide>
            <LayoutSlide>
                <ContactBlock />
            </LayoutSlide>
        </Layout>
    )
}