import Header1 from "../base/header1";
import Header2 from "../base/header2";
import useSmoothScrollTo from "../../lib/smoothScroll";

const MainBlock = () => {
    const homeBind = useSmoothScrollTo('#home');

    return (
        <section className="relative container" {...homeBind}>
            <div className="absolute -top-28 left-1/3 h-96 w-96 decoration rounded-full z-[0]" />
            <div className="absolute bottom-0 left-64 h-48 w-48 decoration rounded-full z-[0]" />
            <div className="relative z-[5] px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center ">
                <div className="lg:flex flex-col w-full md:w-1/6 justify-center items-start text-center md:text-left hidden">
                    <Header1 text="Nelli&nbsp;&nbsp;Pronevich" className="lg:-rotate-90 lg:-ml-12 lg:mt-4 xl:ml-16 xl:mt-0 xxl:ml-48" />
                </div>
                <div className="w-full md:w-5/6 py-6 grid justify-items-stretch">
                    <Header1 text="Nelli&nbsp;Pronevich" className="w-full justify-self-end -mb-3 lg:hidden" />
                    <Header2 text="fashion producer" className="w-full lg:w-10/12 xl:w-8/12 justify-self-center mb-3 lg:mb-0" />
                    <img className="w-10/12 xl:w-8/12 justify-self-center hidden lg:block" src="images/photo/main.jpg" />
                    <img className="w-full justify-self-end lg:hidden block" src="images/photo/main-sm.jpg" />
                    {/* <Header2 text="«Еще не видел тех, с кем нельзя договориться»" className="w-full lg:w-10/12 xl:w-8/12 justify-self-end mb-3 lg:mb-0 font-comforter mt-5 text-center" /> */}
                </div>
            </div>
        </section>
    )
}

export default MainBlock