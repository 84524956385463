import { useState } from "react";
import Header2 from "../base/header2";
import Header3 from "../base/header3";
import Slider from "../base/slider";
import useSmoothScrollTo from "../../lib/smoothScroll";

const data = [
    {
        title: "TREND ANALYTICS",
        link: "trend-analytic",
    },
    {
        title: "PROMO SWEET-SHOP",
        link: "promo-sweetshop",
    },
    {
        title: "PROMO AMBRETTA",
        link: "promo-ambretta",
    },
    {
        title: "LOOKBOOK MENSLOOK",
        link: "lookbook-menslook",
    },
    {
        title: "SVADBAMIR MENS",
        link: "svadbamir-mens",
    },
    {
        title: "CAMPAIGN SVADEBMIR",
        link: "campaign-svadebmir",
    },
    {
        title: "PROMO ZDRAVKOVICH",
        link: "promo-zdravkovich",
    },
    {
        title: "CATALOG WB ABOBRAND",
        link: "catalog-abobrand",
    },
    {
        title: "LOOKBOOK WB ABOBRAND",
        link: "lookbook-abobrand",
    },
]

const ProjectsBlock = () => {
    const projectBind = useSmoothScrollTo('#project');
    const [currentProject, setCurrentProject] = useState(0);

    const updateTitle = (index) => {
        index = index < 0 ? data.length - 1 : index;
        setCurrentProject(index % data.length)
    }

    return (
        <section className="container relative overflow-y-hidden pb-12" {...projectBind}>
            <div className="absolute bottom-24 right-0 h-64 w-64 decoration rounded-full z-[0]" />
            <div className="px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center relative z-10 h-full">
                <div className="flex flex-col w-full md:w-2/6 justify-center items-start md:text-left -mb-12 lg:mb-0">
                    <Header2 text={data[currentProject].title} className="" />
                    <Header3 text="Project" className="" />
                    <a href={data[currentProject].link} target="_blank" rel="noreferrer" className="hidden lg:inline-flex cursor-pointer items-center text-white bg-violet-500 hover:bg-violet-900 border-0 py-3 px-5 focus:outline-none  rounded text-base mt-4 md:mt-0">Подробнее</a>
                </div>
                <div className="h-full w-full lg:w-4/6">
                    <Slider data={data} index={0} onUpdate={updateTitle} currentProject={currentProject % data.length} />
                </div>
            </div>
        </section>
    )
}

export default ProjectsBlock