import Slider1 from "../base/slide1";
import useSmoothScrollTo from "../../lib/smoothScroll";

const FeedbackBlock = () => {
    const feedBind = useSmoothScrollTo('#feedback');

    return (
        <section {...feedBind} className="container px-3 py-4 mx-auto flex flex-wrap flex-col md:flex-row items-center relative pb-12">
            <div className="flex flex-col text-center w-full">
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-violet-500">Отзывы</h1>
            </div>
            <div className="h-full w-full hidden lg:block">
                <Slider1 index={2} slidesPerView={2} />
            </div>
            <div className="h-full w-full lg:hidden">
                <Slider1 index={3} slidesPerView={1} />
            </div>
        </section>
    )
}

export default FeedbackBlock