import useSmoothScrollTo from "../../lib/smoothScroll";
import axios from 'axios';
import Alert from "./alert";
import React, { useState, useEffect } from 'react';


const ContactBlock = () => {
    const contactBind = useSmoothScrollTo('#contact');

    const [alertText, setAlertText] = useState("");
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        brand: '',
        phone: '',
        email: '',
        comments: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                setVisible(false);
            }, 5000); // Скрыть уведомление через 5 секунд

            return () => {
                clearTimeout(timer);
            };
        }
    }, [visible]);

    const handleSubmit = async () => {
        try {
            const response = await axios.post('https://proonya.ru/send-to-telegram', formData);
            let isSuccess = response.status === 200;
            setError(!isSuccess);
            if (isSuccess) {
                setAlertText("Данные успешно отправлены :)");
                setFormData({
                    name: '',
                    brand: '',
                    phone: '',
                    email: '',
                    comments: '',
                });
            } else {
                setAlertText("Произошла ошибка :(");
            }
            setVisible(true);
        } catch (error) {

        }
    };

    return (
        <section {...contactBind} className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center relative mb-24">
            <Alert error={error} text={alertText} visible={visible} setVisible={setVisible} />
            <div className="flex flex-col text-center w-full mb-12">
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-violet-500">Контакты</h1>
            </div>
            <div className="flex flex-col w-full md:w-3/6 justify-center items-start md:text-left lg:mb-0">

                <div className="container px-5 mx-auto mb-24">
                    <div className="flex flex-wrap justify-center">
                        <div className="p-4 w-full text-center flex items-center">
                            <div className="flex items-center justify-center w-10 h-10">
                                <img className="w-full h-full" src="images/svg/phone.svg" />
                            </div>
                            <div className="flex-grow text-left ml-3">
                                <h1 className="sm:text-2xl text-lg font-medium title-font mb-2 text-white">8(923)-435-97-09</h1>
                            </div>
                        </div>
                        <div className="p-4 w-full text-center flex items-center">
                            <div className="flex items-center justify-center w-10 h-10">
                                <img className="w-full h-full" src="images/svg/email.svg" />
                            </div>
                            <div className="flex-grow text-left ml-3">
                                <h1 className="sm:text-2xl text-lg font-medium title-font mb-2 text-white">pronevich.nelli@bk.ru</h1>
                            </div>
                        </div>
                        <div className="p-4 w-full text-center flex items-center">
                            <div className="flex items-center justify-center w-10 h-10">
                                <img className="w-full h-full" src="images/svg/telegram.svg" />
                            </div>
                            <div className="flex-grow text-left ml-3">
                                <a href="https://t.me/proonya" className="bg-violet-500 hover:bg-violet-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Связаться</a>
                            </div>
                        </div>
                        <div className="p-4 w-full text-center flex items-center">
                            <div className="flex items-center justify-center w-10 h-10">
                                <img className="w-full h-full" src="images/svg/vk.svg" />
                            </div>
                            <div className="flex-grow text-left ml-3">
                                <a href="https://vk.com/prooonya" className="bg-violet-500 hover:bg-violet-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Связаться</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-full w-full lg:w-3/6">

                <p class="text-center text-neutral-700 dark:text-neutral-200 mb-6"><span className="highlighted-text-7 text-2xl text-center">Оставьте заявку, и мы свяжемся с вами в течение часа, чтобы обсудить стоимость вашего проекта.</span></p>
                <form className="bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="name"
                            type="text"
                            placeholder="Ваше имя"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="brand"
                            type="text"
                            placeholder="Ссылки или название бренда"
                            value={formData.brand}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="phone"
                            type="phone"
                            placeholder="Телефон"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="comments"
                            placeholder="Ваш комментарий"
                            value={formData.comments}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-violet-500 hover:bg-violet-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleSubmit}
                        >
                            Отправить информацию
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default ContactBlock