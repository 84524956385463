import React from "react";
import SliderCard from "./sliderCard";
import LeftArrow from "../icons/leftArrow"

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';

const Slider = ({ data, index, onUpdate, currentProject, slidesPerView = 1 }) => {
    return (
        <div className="mt-16 relative lg:w-full z-30 mb-12">
            <div className="absolute -bottom-14 xl:h-full xl:inset-y-0 left-0 sm:left-12 w-12 z-10">
                <div className="cursor-pointer h-full flex items-center" id={`prevSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <div className="absolute -bottom-12 xl:h-full xl:inset-y-0 inset-x-20 sm:inset-x-28 w-36 z-10 lg:hidden">
                <a href={data[currentProject].link} target="_blank" rel="noreferrer" className="cursor-pointer items-center text-white bg-violet-500 hover:bg-violet-900 border-0 py-2 px-8 focus:outline-none  rounded text-base mt-4 md:mt-0 w-full">Подробнее</a>
            </div>
            <div className="absolute -bottom-14 xl:h-full xl:inset-y-0 right-0 sm:right-12 w-12 z-10">
                <div className="cursor-pointer h-full rotate-180 flex items-center" id={`nextSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={slidesPerView}
                loop={true}
                effect={"fade"}
                modules={[EffectFade, Navigation]}
                navigation={{
                    nextEl: '#nextSlide' + index,
                    prevEl: '#prevSlide' + index,
                }}
                onSlideChange={(s) => onUpdate(s.activeIndex - 1)}
            >
                {
                    data.map((item, index) => {
                        return <SwiperSlide key={index} className="">
                            <SliderCard number={index} title={item.title} />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
}

export default Slider

