import { useState, useRef, useEffect } from "react"

const Picker = ({ values, currentValue, setCurrentValue }) => {
    const [isOpened, setIsOpened] = useState(false);

    const rootButton = useRef(null);

    useEffect(() => {
        const onClick = e => rootButton.current.contains(e.target) || setIsOpened(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <div className="flex justify-center">
            <div className="relative inline-block" ref={rootButton}>
                <button className="w-44 relative z-10 flex items-center justify-between p-2 text-sm text-gray-600 bg-white border border-transparent rounded-md focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:text-white dark:bg-gray-800 focus:outline-none" onClick={() => setIsOpened(value => !value)}>
                    <span className="mx-1">{currentValue}</span>
                    <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                    </svg>
                </button>

                <div className={`absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl dark:bg-gray-800 ${isOpened ? "block" : "hidden"}`}>
                    {
                        values.map((item, index) => {
                            return <button
                                onClick={() => {
                                    setCurrentValue(item);
                                    setIsOpened(false);
                                }}
                                key={index} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white w-full">
                                {item}
                            </button>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Picker