const LeftArrow = ({ color = "#F3F5F9" }) => {
    return (
        <div className="">
            <svg width="36px" height="36px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <polyline fill="none" stroke={color} strokeWidth="2" points="7 2 17 12 7 22" transform="matrix(-1 0 0 1 24 0)" />
            </svg>
        </div>
    )
}

export default LeftArrow