const SliderCard = ({ number }) => {

    return (
        <div className="flex items-center justify-center">
            <div className="flex flex-col items-end px-1.5 lg:px-3">
                <img
                    className="object-cover mb-3 lg:mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                    src={`images/photo/${number}/1.jpg`}
                    alt=""
                />
                <img
                    className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                    src={`images/photo/${number}/2.jpg`}
                    alt=""
                />
            </div>
            <div className="px-1.5 lg:px-3">
                <img
                    className="mb-3 lg:mb-6 object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                    src={`images/photo/${number}/3.jpg`}
                    alt=""
                />
                <img
                    className="object-cover rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                    src={`images/photo/${number}/4.jpg`}
                    alt=""
                />
            </div>
        </div>
    )
}

export default SliderCard