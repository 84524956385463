import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const TeamCard = ({ item }) => {
  // const FadeIn = styled.div`animation: 1s ${keyframes`${fadeIn}`} 1s`;

  const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

  const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

  const Fade = styled.div`
  display: inline-block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.5s linear;
  transition: visibility 0.5s linear;
`;

  return (
    <div className="p-4 lg:w-1/3" >
      <Fade>
        <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
          <img loading="lazy" className="image-with-animation flex-shrink-0 rounded-lg w-24 h-24 lg:w-48 lg:h-48 object-cover object-center sm:mb-0 mb-4" src={item.img} />
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-2xl text-violet-500">{item.name}</h2>
            <h3 className="text-white text-lg">{item.job}</h3>
            <h3 className="text-gray-500 text-lg mb-3">{item.city}</h3>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default TeamCard