const GetPhotos = (n) => {
    const newArr = [];
    for (let i = 1; i <= n; i++) {
        newArr.push(i);
    }
    return newArr;
}

const projectsData = [
    {
        title: "PROMO ZDRAVKOVICH",
        path: "promo-zdravkovich",
        photos: GetPhotos(11)
    },
    {
        title: "PROMO SWEET-SHOP",
        path: "promo-sweetshop",
        photos: GetPhotos(17)
    },
    {
        title: "PROMO AMBRETTA",
        path: "promo-ambretta",
        photos: GetPhotos(24)
    },
    {
        title: "LOOKBOOK MENSLOOK",
        path: "lookbook-menslook",
        photos: GetPhotos(31)
    },
    {
        title: "SVADBAMIR MENS",
        path: "svadbamir-mens",
        photos: GetPhotos(18)
    },
    {
        title: "CAMPAIGN SVADEBMIR",
        path: "campaign-svadebmir",
        photos: GetPhotos(51)
    },
    {
        title: "POLYGRAHY",
        path: "polygrahy",
        photos: GetPhotos(7)
    },
    {
        title: "TREND ANALYTICS",
        path: "trend-analytic",
        photos: GetPhotos(31)
    },
    {
        title: "CATALOG FOR WB ABOBRAND",
        path: "catalog-abobrand",
        photos: GetPhotos(50)
    },
    {
        title: "LOOKBOOK FOR WB ABOBRAND",
        path: "lookbook-abobrand",
        photos: GetPhotos(50)
    },
]

export default projectsData