const ProductionInfo = () => {
    return (
        <div className="px-5 py-24 mx-auto  w-5/6">
            <div className="text-center mb-20">
                <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-violet-500 mb-4">PRODUCTION съёмки</h1>
            </div>
            <div className="flex flex-wrap -m-4 w-full">
                <div className="p-4 lg:w-1/3 w-full">
                    <h2 className="font-medium title-font tracking-widest text-violet-500 mb-4 text-sm text-center sm:text-left">ПРЕПРОДАКШН</h2>
                    <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5">
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Определение цели
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Создание и согласование концепции съемки и сметы
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Поиск и выбор локации съемки
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Подбор оборудования и реквизита
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Подбор стиля и макияжа
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Подбор команды для проекта
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Работа с документами проекта
                        </a>
                    </nav>
                </div>
                <div className="p-4 lg:w-1/3 w-full">
                    <h2 className="font-medium title-font tracking-widest text-violet-500 mb-4 text-sm text-center sm:text-left">ПРОДАКШН</h2>
                    <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5">
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Контроль съемочного процесса
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Контроль съемки на соответствие техническому заданию
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Контроль сроков проекта и съемки
                        </a>
                    </nav>
                </div>
                <div className="p-4 lg:w-1/3 w-full">
                    <h2 className="font-medium title-font tracking-widest text-violet-500 mb-4 text-sm text-center sm:text-left">ПОСТПРОДАКШ</h2>
                    <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5">
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Контроль постпродакшена съемки
                        </a>
                        <a>
                            <span className="bg-violet-100 text-violet-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Отчет и закрывающие документы
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default ProductionInfo