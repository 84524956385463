import { useRoutes } from './routes'
import { BrowserRouter } from 'react-router-dom'

import 'swiper/scss'
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";

function App() {
  const routes = useRoutes();

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );
}

export default App;
