import { Routes, Route, Navigate } from 'react-router-dom'
import { ProjectPage } from "./pages/projectPage"
import { MainPage } from "./pages/mainPage"
import projectsData from './data/projectData'

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            {
                projectsData.map((item, index) => {
                    return <Route path={`/${item.path}`} key={index} element={<ProjectPage item={item}/>} />
                })
            }
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}