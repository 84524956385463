import Header3 from "../base/header3";

const Header = ({ isMain }) => {
    if (!isMain) {
        return (
            <header className="fixed flex items-center sm:flex-row flex-col mx-auto w-full bg-mainColor">
                <a href="/">
                    <Header3 text={`Nelli Pronevich`} className="px-5 py-6 cursor-pointer text-white" />
                </a>
            </header>
        );
    }

    return (
        <header className="body-font fixed w-full z-[100] hidden lg:block">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <p className="">

                </p>
                <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center  font-bold">
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#home">Home</a>
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#info">Info</a>
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#project">Projects</a>
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#production">Production</a>
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#feedback">Feedback</a>
                    <a className="mr-5 text-white hover:underline cursor-pointer" href="#team">Team</a>
                    <a href="#contact" className="cursor-pointer inline-flex items-center text-white bg-violet-500 hover:bg-violet-900 border-0 py-3 px-5 focus:outline-none  rounded text-base mt-4 md:mt-0">Связаться</a>
                </nav>
            </div>
        </header>
    );
};

export default Header