import Header2 from "../base/header2"

const ContentBlock = ({ content }) => {
    console.log(content.photosCount);
    return (
        <section className="container body-font  px-5 py-24 mx-auto">
            <Header2 text={content.title} className="text-white mb-6" />
            <div className="">
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                    {
                        content.photos.map((item, index) => {
                            return <div className="p-4 md:w-1/3 sm:mb-0 mb-6" key={index}>
                                <img alt="content" className="object-cover object-center h-full w-full" src={`images/photo/${content.path}/${index + 1}.jpg`} />
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default ContentBlock