import ContentInfo from "../base/contentInfo";
import useSmoothScrollTo from "../../lib/smoothScroll";

const InfoBlock = () => {
    const infoBind = useSmoothScrollTo('#info');

    return (
        <section {...infoBind} className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center relative">
            <ContentInfo />
        </section>
    )
}

export default InfoBlock