import React from "react";
import LeftArrow from "../icons/leftArrow"
import FeedCard from "./feedbackCard";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';


const data = [
    {
        name: "Юлия",
        text1: 'Нелли, спасибо за организацию😍 и остальным за классную работу 🥺💔',
        text2: "",
        text3: "",
    },
    {
        name: "Виктория",
        text1: 'Спасибо большое за возможность принять участие в таком крутом проекте💙',
        text2: "",
        text3: "",
    },
    {
        name: "Вера",
        text1: 'Благодарю за сотрудничество, было интересно принять участие и посмотреть на процесс😌',
        text2: "",
        text3: "",
    },
    {
        name: "София",
        text1: 'Всем большое спасибо, вы крутая команда 🙌🏻🌿',
        text2: "",
        text3: "",
    },
    {
        name: "Карина",
        text1: 'Была очень рада поработать в таком масштабном проекте!',
        text2: "Неля, не устану повторять - ты большая молодец!!❤",
        text3: "Вся команда тоже большие проффесионалы, с вами было очень комфортно 💕💕💕",
    },
    {
        name: "Валерия",
        text1: 'Девочки, хочу еще раз вас всех поблагодарить! Это просто нереально красиво😍, действительно крутой результат',
        text2: "И отдельное спасибо Нелли, за такой уровень!",
        text3: "",
    },
    {
        name: "Лейла",
        text1: 'Это так красиво❤ ',
        text2: "Просто нет слооов, одни эмоции ",
        text3: "Залипаю на каждую фотографию",
    },
    {
        name: "Вера",
        text1: 'Хотела бы выразить огромную благодарность за такой чудесный образ, за такие съемки. Ты большая молодец, все до таких тонкостей продумала и показала в каких то ситуациях как лучше встать, как руку сложить, какую эмоцию показать',
        text2: "Проект выдался настолько реалистичным, что многие подумали, что это правда😅",
        text3: "",
    },
    {
        name: "Юлия",
        text1: 'Нелли! Огромное спасибо за прекрасную организацию и участие в проекте Острые Козырьки! За помощь в подборе моделей, лучших визажистов! За полную отдачу себя на проекте! С тобой реально работать одно удовольствие)))',
        text2: "",
        text3: "",
    },
    {
        name: "Елена",
        text1: 'Нелли, хочу тебя поблагодарить за твою работу!',
        text2: "Организация обоих проектов на высшем уровне, ты большая молодец! ",
        text3: "Все именно так, как я и хотела, все мои хотелки ты воплатила в жизнь😌",
    },
    {
        name: "Елена",
        text1: 'Команда была подобрана идеально. Реквизиты, локации - все отлично! Но особенно меня восхитила ты на съемочной площадке - ты успевала и помочь моделям с позированием, и реквизит вовремя подать, я в шоке!)) ',
        text2: "С тобой съемка прошла гораздо продуктивнее обычного. Спасибо❤",
        text3: "",
    },
    {
        name: "Валерия",
        text1: 'Нелли, ты золотко - собрала настоящих профи и организовала все на высшем уровне! Это были два дня нереально крутой, слаженой работы огромной команды. Один только бекстейдж чего стоит - там уже есть то, чего я так ждала! Боюсь представить сколько еще красоты будет, когда девочки скинут свои работы... Как выбирать фоточки для постов?! Я уже паникую, что это будет слишком сложно😂',
        text2: "",
        text3: "",
    },
    {
        name: "Каролина - Свадебный мир",
        text1: 'Нелли, добрый день. Все прекрасно, фотографии отличные!) Рады сотрудничеству🌷',
        text2: "",
        text3: "",
    },
    {
        name: "Екатерина",
        text1: 'Честно: все заебись😂❤',
        text2: "Я довольна более чем. Насчет задержек я сильно не парилась, настроила себя на то, что фотки могут долго делаться)) ",
        text3: "Поэтому я прям максимально довольна. Фоток много, ракурсы разные, реквизиты - портфолило явно будет чем разнообразить на всех сайтах",
    },
    {
        name: "София - MENSLOOK",
        text1: 'Привет! Впечатления от работы с тобой как с продюсером съемок только положительные. ',
        text2: "Особенно понравился твой подход к организации проектов и подбору специалистов! Интересные творческие идеи в которых хочется принимать участие🌿 Всегда четкий тайминг, что не нельзя не отметить🙌🏻 ",
        text3: "Будем рады новым проектам😉",
    },
    {
        name: "Екатерина - Кондитер",
        text1: 'Когда проходила съемка, даже мысль не закрадывалась, что что-то пойдёт не так и что-то не получится. Потому что с нами работали только профессионалы, которых Нелли тщательно отобрала. Для меня это был первый опыт в съёмке моей продукции и я осталась довольна) Ну и конечно результат- это бомба 💣',
        text2: "Нелли, спасибо большое 😊",
        text3: "",
    },
    {
        name: "Татьяна",
        text1: 'Всем привет! Я в восторге! Это очень круто 😍',
        text2: "Нелли, спасибо",
        text3: "",
    },
    {
        name: "Карина",
        text1: 'Неля, спасибо что выбрала в команду нас❤❤❤',
        text2: "Ты осуществила мою мечту на публкацию😭😭",
        text3: "",
    },
    {
        name: "Дарья",
        text1: 'Я была очень удевлена такой организации, от начала и до конца! Спасибо тебе огромное, было безумно комфортно и приятно работать. Ты такая молодец! ',
        text2: "",
        text3: "",
    },
    {
        name: "Каролина - Свадебный мир",
        text1: 'Всем добрый день!',
        text2: "ОЧЕНЬ чувственные видео🔥👏",
        text3: "",
    },
    {
        name: "Евгения",
        text1: "Все отлично! Фотки классные, про команду ты и так все знаешь - все как всегда огонь! Я соберусь с мыслями и напишу это все в чат еще ребятам, я довольна как слон)))",
        text2: "",
        text3: "",
    },
    {
        name: "Ирина - AMBRETTA",
        text1: "Потрясающий организатор съемок! Нелли предложила снять рекламу для бренда моих украшений и я с радостью согласилась, так как давно восхищаюсь ее работами. Такого профессионального подхода к съемкам я еще не встречала, есть чему поучиться! Нелли расписала все поминутно, съёмка была за городом, была привлечена большая команда, свыше 12 человек. Итоговые фото и видео поразили меня в самое сердце 💘",
        text2: "",
        text3: "",
    },
    {
        name: "Ирина - AMBRETTA",
        text1: "Я дейсвительно влюбилась в эти фотографии, они безумно красивые и атмосферные. Даже не верится, что они сделаны всего за пару часов. ",
        text2: "Украшения на снимках выглядят безупречно, вышло что-то большее, чем коммерческая съемка, в снимках есть душа. Именно такие фотографиии цепляют взглядом и остаются в памяти надолго.",
        text3: "Могу лишь сказать, что работа с Нелии станет для вас большим везением, не упустите свой шанс!",
    },
    {
        name: "Татьяна",
        text1: "Во-первых, съемка получилась огонь! Очень крутая организация с твоей стороны, прямо пушка. Все участники правда оказались очень профессиональными;",
        text2: "Ну и организовать за достаточно короткий срок - это все достойно высоких похвал 💯🙌🏼",
        text3: "Во-вторых, я хочу сказать тебе большое спасибо, что приглашала и приглашаешь меня участвовать в проектах. В совокупности с твоей организацией получается все очень грандиозное 🔥 Я благодарна, потому что очень хочу развиваться, а участие в таких бомбезных коллаборациях для меня это из разряда собрать сопли в кулак и только вперёд. Спасибо, это так ценно ❤️",
    },
]

const Slider1 = ({ index, slidesPerView = 3 }) => {
    return (
        <div className="mt-16 relative lg:w-full z-30 lg:px-24">
            <div className="absolute -bottom-12 lg:bottom-0 xl:h-full left-8 w-36 z-10">
                <div className="cursor-pointer h-full flex items-center" id={`prevSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <div className="absolute -bottom-12 lg:bottom-0 xl:h-full right-8 w-36 z-10">
                <div className="cursor-pointer h-full rotate-180 flex items-center" id={`nextSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={slidesPerView}
                loop={true}
                modules={[Navigation]}
                navigation={{
                    nextEl: '#nextSlide' + index,
                    prevEl: '#prevSlide' + index,
                }}
            >
                {
                    data.map((item, index) => {
                        return <SwiperSlide key={index} className="h-full">
                            <div className="flex flex-wrap -m-4 h-full">
                                <FeedCard data={item} />
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
}

export default Slider1

