import React from "react";
import LeftArrow from "../icons/leftArrow"

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';

const SliderInfo = ({ slides, index, slidesPerView = 1 }) => {
    return (
        <div className="mt-16 relative lg:w-full z-30 mb-12">
            <div className="absolute -bottom-12 xl:h-full xl:inset-y-0 left-12 w-36 z-10">
                <div className="cursor-pointer h-full flex items-center" id={`prevSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <div className="absolute -bottom-12 xl:h-full xl:inset-y-0 right-12 w-36 z-10">
                <div className="cursor-pointer h-full rotate-180 flex items-center" id={`nextSlide${index}`}>
                    <LeftArrow color="#8b5cf6" />
                </div>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={slidesPerView}
                loop={true}
                modules={[EffectFade, Navigation]}
                navigation={{
                    nextEl: '#nextSlide' + index,
                    prevEl: '#prevSlide' + index,
                }}
            >
                {
                    slides.map((item, index) => {
                        return <SwiperSlide key={index} className="">
                            {item}
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
}

export default SliderInfo

