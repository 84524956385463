import { SwiperSlide } from 'swiper/react';
import SliderInfo from './sliderInfo';

const ContentInfo = () => {
    const card1 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
        <div class="lg:w-2/3 pl-4 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left relative z-10">
            <p class="leading-relaxed text-lg mb-4"><span className="text-xl text-violet-500 font-bold">FASHION PRODUCER</span> стоит у истоков любой задумки, является <span className="highlighted-text-2 font-bold">генератором</span> актуальных идей и решений, <span className="highlighted-text-3 font-bold">ведёт проект </span>до победного конца, грамотно <span className="highlighted-text-4 font-bold">презентует и выпускает</span> работу в мир индустрии. Кроме того, <span className="highlighted-text-5 font-bold">заботится</span> о каждом человеке в команде, <span className="highlighted-text-6 font-bold">подбирает</span> локации, реквизит, <span className="highlighted-text-7 font-bold">рассчитывает</span> бюджет и <span className="highlighted-text-8 font-bold">контролирует</span> соблюдение временных рамок.</p>
        </div>
    </div>;
    const card2 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-2/3 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left">
            <p class="leading-relaxed text-xl mb-4"><span className="text-violet-500 font-bold">СБОР КОМАНДЫ</span></p>
            <p class="leading-relaxed text-lg mb-4">Как продюсер я обладаю <span className="highlighted-text-9 font-bold">коммуникабельностью</span> и умею находить контакт с нужными людьми.</p>
            <p class="leading-relaxed text-lg mb-4 font-extralight">«Ещё не видел тех, с кем нельзя договориться»</p>
            
            
            <p class="leading-relaxed text-lg mb-4">К каждой съёмке стараюсь привлечь <span className="highlighted-text-1 font-bold">лучших специалистов</span>, собрать всех в одну команду и наладить коммуникацию таким образом, чтобы коллектив работал как <span className="highlighted-text-2 font-bold">единый организм</span>, вписывался во все дедлайны и добивался наилучшего <span className="highlighted-text-3 font-bold">результата!</span></p>
        </div>
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
    </div>;
    const card3 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
        <div class="lg:w-2/3 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left">
            <p class="leading-relaxed text-xl mb-4"><span className="text-violet-500 font-bold">КОНЦЕПЦИЯ И ОРГАНИЗАЦИЯ СЪЁМКИ</span></p>
            <p class="leading-relaxed text-lg mb-4">Это именно то, с чего начинается <span className="highlighted-text-4 font-bold">любой проект</span>. Продюсер в первую очередь <span className="highlighted-text-5 font-bold">продумывает идею</span>, а далее занимается <span className="highlighted-text-6 font-bold">организацией съемочного пространства</span>: подбор локации, реквизита, обеспечение комфортных условий для работы, решение технических неполадок и быстрая реакция на устранение любых форс-мажорных ситуаций.</p>
        </div>
    </div>;
    const card4 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-2/3 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left">
            <p class="leading-relaxed text-xl mb-4"><span className="text-violet-500 font-bold">ОТВЕТСТВЕННОСТЬ</span></p>
            <p class="leading-relaxed text-lg mb-4">Зона ответсвенности продюсера <span className="highlighted-text-7 font-bold">начинается</span> с глобальной идеи съёмки и <span className="highlighted-text-8 font-bold">заканчивается</span> чистотой кисточек для макияжа, работающего на площадке визажиста. Фешн-продюсер <span className="highlighted-text-9 font-bold">всегда готов</span> отвечать за все происходящее. <span className="highlighted-text-1 font-bold">Каждое решение</span> в команде является осознанным шагом, риском, авантюрой, за которую <span className="highlighted-text-2 font-bold">несёт ответственность именно продюсер</span>.</p>
        </div>
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
    </div>;
    const card5 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
        <div class="lg:w-2/3 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left">
            <p class="leading-relaxed text-xl mb-4"><span className="text-violet-500 font-bold">ТАЙМИНГ</span></p>
            <p class="leading-relaxed text-lg mb-4">В задачи фешн-продюсера входит <span className="highlighted-text-3 font-bold">контроль времени</span> работы каждого съемочного дня. Именно продюсер <span className="highlighted-text-4 font-bold">синхронизирует часы</span> работы каждого специалиста, нужное для концепции суток времени, часы аренды помещения, сроки сдачи работы, и, конечно, отвечает за главный <span className="highlighted-text-5 font-bold">дедлайн-выпуск проекта</span>.</p>
        </div>
    </div>;
    const card6 = <div class="flex flex-col lg:flex-row ">
        <div class="lg:w-2/3 lg:pl-8 lg:py-8 lg:border-l border-violet-500 lg:border-t-0 border-t mt-4 pt-4 lg:mt-0 text-center lg:text-left">
            <p class="leading-relaxed text-xl mb-4"><span className="text-violet-500 font-bold">РАСЧЁТ И КОНТРОЛЬ БЮДЖЕТА</span></p>
            <p class="leading-relaxed text-lg mb-4">Когда команда находится в <span className="highlighted-text-6 font-bold">творческом потоке</span>, есть риск выйти за рамки доступного бюджета и  <span className="highlighted-text-7 font-bold">сорвать съёмочной процесс</span>. Именно поэтому продюсер <span className="highlighted-text-8 font-bold">контролирует финансы</span>, делая все возможное для того, чтобы проект был максимально бюджетным, но при этом не терял масштабности и оригинальности.</p>
        </div>
        <div class="lg:w-1/3 text-center lg:pr-8 lg:py-8 hidden lg:block">

        </div>
    </div>;

    const slides = [card1, card2, card3, card4, card5, card6]
    return (
        <div class="text-white body-font mb-10">
            <div class="container px-6 flex flex-col">
                <div class="lg:w-4/6 mx-auto hidden lg:block">
                    {card1}
                    {card2}
                    {card3}
                    {card4}
                    {card5}
                    {card6}
                </div>
                <div className="h-full w-full lg:hidden">
                    <SliderInfo index={4} slides={slides} />
                </div>
            </div>
        </div>
    )
}

export default ContentInfo