import ProductionInfo from "../base/productionInfo";
import useSmoothScrollTo from "../../lib/smoothScroll";

const ProductionBlock = () => {
    const productionBind = useSmoothScrollTo('#production');

    return (
        <section className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center relative" {...productionBind}>
            <ProductionInfo />
        </section>
    )
}

export default ProductionBlock